/*------------------------------------*\
	@COMPONENTS #MODAL
\*------------------------------------*/

.modal {
	z-index: 9999999;
	background-color: rgba(#15100e, 0.7);
	transition: all .8s ease;
}

.modal-dialog {
	width: calc(100% - 20px);
	max-width: 1000px;
	margin-top: 200px;
	position: relative;
	overflow: visible;

	button {
		position: absolute;
		top: -40px;
		right: 0;
		z-index: 99999;
		color: #fff;
		background-color: transparent;
	}

	@media (max-width: $screen-md) {
		max-width: 600px;
		margin-top: 100px;
	}
}
