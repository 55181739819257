/*------------------------------------*\
    @BASE #LINKS
\*------------------------------------*/
a {
    outline: none !important;  /* removing outline in Mozilla */
    text-decoration: none;
    color: $link-clr;
    cursor: pointer;
    @include trans;

    word-break: break-word;
    word-break: break-all;

    &:visited, &:active, &:focus {
        color: $link-clr;
    }

    &:hover {
        color: $link-hover;
        text-decoration: none;
    }
}