/*------------------------------------*\
    @BASE #PAGE
\*------------------------------------*/

html {
    min-height: 100%;
    height: 100%;
    -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
     -webkit-font-smoothing: antialiased;
}

body {
    font-family: $base-font;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: $base-font-weight;
    background-color: $base-bgr;
    color: $text-clr;
    min-height: 100%;
    position: relative;
}

//SELECTED TEXT
@include selected-text;