/*------------------------------------*\
    @OBJECTS #LIST-INLINE
\*------------------------------------*/

.list-inline {
    margin:  0;
    padding: 0;
    list-style: none;

    > li {
        display: inline-block;
    }
}
