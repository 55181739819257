//------------------------------------\\
//  @TOOLS #MIXINS
//------------------------------------//

//SELECTED TEXT MIXIN
@mixin selected-text {
    ::-moz-selection {
        background: $selected-text-bgr;
        color: $selected-text-clr;
        text-shadow: none;
    }
    ::selection {
        background: $selected-text-bgr;
        color: $selected-text-clr;
        text-shadow: none;
    }
}

//UNSELECTABLE
@mixin unselectable {
    -moz-user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -o-user-select: none;
    user-select: none;
}

//CLEARFIX
@mixin clearfix() {
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
}

//BARE LIST
@mixin list {
    list-style: none;
    padding: 0;
    margin: 0;
}

//HIDE
@mixin hide-hide {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

//TRANSITION
@mixin trans {
    transition: all .3s ease 0s;
}

//LINE-HIGH
@mixin lh($line-height) {
    height: $line-height;
    line-height: $line-height;
}


@mixin box($dimension) {
    width: $dimension;
    height: $dimension;
    display: block;
}

@mixin hop($opac) {
    @include trans;

    &:hover {
        @include opacity($opac);
    }
}


//TRIANGLES
@mixin triangles {
    content: '';
    width: 0;
    height: 0;
    display: block;
    border-style: solid;
    position: absolute;
}

@mixin tt($w1, $w2, $clr) {
    @include triangles;
    border-width: 0 $w1 $w2 $w1;
    border-color: transparent transparent $clr transparent;
}
@mixin tb($w1, $w2, $clr) {
    @include triangles;
    border-width: $w2 $w1 0 $w1;
    border-color: $clr transparent transparent transparent;
}
@mixin tr($w1, $w2, $clr) {
    @include triangles;
    border-width: $w1 0 $w1 $w2;
    border-color: transparent transparent transparent $clr;
}
@mixin tl($w1, $w2, $clr) {
    @include triangles;
    border-width: $w1 $w2 $w1 0;
    border-color: transparent $clr transparent transparent;
}

//VISIBILITY
@mixin hide {
    opacity: 0;
    visibility: hidden;
}
@mixin show {
    opacity: 1;
    visibility: visible;
}

//VERTICAL ALIGN 
@mixin va {
  position: relative;
  top: 50%;
  @include transform(translateY(-50%));
}

@mixin center {
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@mixin centerV {
    position: absolute;
    top:50%;
    transform: translateY(-50%);
}
@mixin centerH {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

// CENTER HORIZONTALLY USING MARGIN 'AUTO'

@mixin center-h-auto {
    margin-right: auto;
    margin-left: auto;
}