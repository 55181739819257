/*------------------------------------*\
	@TRUMPS #LAYOUT
\*------------------------------------*/

.row + .row {
	@media (max-width: $screen-sm) {
		margin-top: 30px;
	}
}

.row {
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
	}
}

*[class*="col-"] {
	@include clearfix;
}

// Separate columns vertically after collapse
// *[class*="col-sm-"] {
// 	@media (max-width: $screen-sm) {
// 		& + *[class*="col-sm-"] {
// 			margin-top: 15px;
// 		}
// 	}
// }


// Expand column to fit container on 'mobile' view
.col-full {
	@media (max-width: $screen-xs) {
		width: 100%;
	}
}

// Reset 'offset' for '.col-full'
.col-offset-full {
		@media (max-width: $screen-xs) {
		margin-left: 0;
	}
}


// Display 'row' children as 'inline-block'

.row--ib {
	font-size: 0;
	> * {
	    float: none;
	    display: inline-block;
	    font-size: $base-font-size;
	    vertical-align: middle;
	}
}

.row--ib-bottom {
	> * {
	    vertical-align: bottom;
	}
}

@for $i from 1 through 10 {
    .spaced#{$i}x {
	   margin-bottom: 10px * $i !important;
	}
}


// Equal card heights
.row-equih {
	margin-left: 0;
	margin-right: 0;
	> * {
		background-color: #ccc;
		border-radius: 10px;
		padding: 30px;
		margin-bottom: 10px;
	}
}

@mixin make-eq-grid($br) {
	@media (min-width: $br) {
		margin-left: -$grid-gutter-width;
		margin-right: -$grid-gutter-width;
		border-spacing: $grid-gutter-width 0;
		border-collapse: separate; 
		> * {
			display: table-cell;
			float: none;
			padding: 20px;
		}
	}	
}

.row-equih--xs {
	@include make-eq-grid($screen-xs);
}

.row-equih--sm {
	@include make-eq-grid($screen-sm);
}

.row-equih--md {
	@include make-eq-grid($screen-md);
}

.row-equih--lg {
	@include make-eq-grid($screen-lg);
}
