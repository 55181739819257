/*------------------------------------*\
	@TRUMPS #IMAGES
\*------------------------------------*/

.img--ib {
	display: inline-block;
}

.img--full {
    width: 100%;
}

img {
	pointer-events: none;
}