/*------------------------------------*\
    @OBJECTS #PACK
\*------------------------------------*/

/**
 * 1. Fill all available space.
 * 2. Cause children to be automatically equally sized.
 */
.pack {
    width: 100%; /* [1] */
    display: table;
    table-layout: fixed; /* [2] */
}

    /**
     * Cause children to adopt table-like structure.
     */
    .pack__item {
        display: table-cell;

        .pack--middle > & {
            vertical-align: middle;
        }

        .pack--bottom > & {
            vertical-align: bottom;
        }
    }

/**
* Cause children to pack up into available space, but not equally sized.
*/
.pack--auto {
    table-layout: auto;
}

