/*------------------------------------*\
    @OBJECTS #BUTTONS
\*------------------------------------*/

button {
    &,
    &:focus,
    &:active {
        outline: none !important;
        border: none;
        @include trans;
    }

    &:focus,
    &:active {
        background-color: inherit;
    }
}

.btn {
    @include unselectable;
    display: inline-block;
    vertical-align: middle;
    font: inherit;
    text-align: center;
    margin: 0;
    cursor: pointer;
    overflow: visible;
    border: none;
    padding: 10px 20px;
    background-color: #eee;
    @include trans;

    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

.btn--full {
    width: 100%;
}