/*------------------------------------*\
	@COMPONENTS #FOOTER
\*------------------------------------*/

.footer {
	// Sticky footer
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

