/*------------------------------------*\
    @BASE #TYPE
\*------------------------------------*/

$heading-size-1:  36px !default;
$heading-size-2:  30px !default;
$heading-size-3:  24px !default;
$heading-size-4:  20px !default;
$heading-size-5:  16px !default;
$heading-size-6:  14px !default;

h1, h2, h3, h4, h5, h6 {
    line-height: 1;
    font-family: $base-font;

     &:first-child {
        margin-top: 0;
    }
}

h1, .h1 {
    font-size: $heading-size-1;
    line-height: 1.1;
    margin-bottom: .25em;
}

h2, .h2 {
    font-size: $heading-size-2;
    line-height: 1.2;
    margin-bottom: .25em;
}

h3, .h3 {
    font-size: $heading-size-3;
}

h4, .h4{
    font-size: $heading-size-4;
}

h5, .h5 {
    font-size: $heading-size-5;
}

h6, .h6 {
    font-size: $heading-size-6;
}

img,
p,
ul,
ol,
pre,
blockquote {
    margin-bottom: $space;

    &:last-child {
        margin-bottom: 0;
    }
}

hr {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $border;
    height: 1px;
    width: 100%;
    margin-bottom: $space;
}


strong {
    font-weight: bold;
}