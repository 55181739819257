/*------------------------------------*\
	@TRUMPS #TYPE
\*------------------------------------*/

.text--sm {
	font-size: $base-font-size / 0.4;
}
.text--md {
	font-size: $base-font-size / 0.2;
}

//TYPE WEIGHTS
.type-light {
    font-weight: 300 !important;
}

.type-normal {
    font-weight: 400 !important;
}

.type-semibold {
    font-weight: 600 !important;
}

.type-bold {
    font-weight: 700 !important;
}

.type-extrabold {
    font-weight: 800 !important;
}

//TYPE TRANSFORMS
.type-uppercase {
    text-transform: uppercase !important;
}

.type-lowercase {
    text-transform: lowercase !important;
}

.type-capitalize {
    text-transform: capitalize !important;
}

.type-no-transform {
    text-transform: none !important;
}