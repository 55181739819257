/*------------------------------------*\
    @OBJECTS #FLEXBOX
\*------------------------------------*/

/* Uncomment and set these variables to customize the grid. */

.flex-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
}

.flex-row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);

  > * {
    padding-left: ($grid-gutter-width / 2);
    padding-right:($grid-gutter-width / 2) ;
  }

}

.flex-col {
	@extend .flex-row;
	flex-direction: column;
}

[class^="flex-col-"] {
  width: 100%;
  &:before,
  &:after {
    display: none !important;
  }
}

.flex-row--sm {
  margin-right: -4px;
  margin-left: -4px;

  > * {
    padding-left: 4px;
    padding-right: 4px;
  }  
}

.flex-row {
	&.reverse{
	  flex-direction: row-reverse;
	}
}

.flex-col {
	&.reverse{
	  flex-direction: column-reverse;
	}
}


@media (max-width: $screen-xs) {
  // Justify content
  .start-xs {
    justify-content: flex-start;
  }

  .center-xs {
    justify-content: center;
  }

  .end-xs {
    justify-content: flex-end;
  }
  
  .around-xs {
    justify-content: space-around;
  }

  .between-xs {
    justify-content: space-between;
  }

  // Align items
  .stretch-xs{
    align-content: stretch;
  }

  .top-xs {
    align-items: flex-start;
  }

  .middle-xs {
    align-items: center;
  }

  .bottom-xs {
    align-items: flex-end;
  }


  .first-xs {
    order: -1;
  }

  .last-xs {
    order: 1;
  }
}

.flex-col-xs,
.flex-col-xs-1,
.flex-col-xs-2,
.flex-col-xs-3,
.flex-col-xs-4,
.flex-col-xs-5,
.flex-col-xs-6,
.flex-col-xs-7,
.flex-col-xs-8,
.flex-col-xs-9,
.flex-col-xs-10,
.flex-col-xs-11,
.flex-col-xs-12 {
  box-sizing: border-box;
  
  flex: 0 0 auto;
  // padding-right: ($grid-gutter-width / 2);
  // padding-left: ($grid-gutter-width / 2);
}

.flex-col-xs {
  width: 100%;
}

.flex-col-xs-1 {
  width: 8.333%;
}

.flex-col-xs-2 {

  width: 16.667%;
}

.flex-col-xs-3 {
  width: 25%;
}

.flex-col-xs-4 {
  width: 33.333%;
}

.flex-col-xs-5 {
  width: 41.667%;
}

.flex-col-xs-6 {
  width: 50%;
}

.flex-col-xs-7 {
  width: 58.333%;
}

.flex-col-xs-8 {
  width: 66.667%;
}

.flex-col-xs-9 {
  width: 75%;
}

.flex-col-xs-10 {
  width: 83.333%;
}

.flex-col-xs-11 {
  width: 91.667%;
}

.flex-col-xs-12 {
  width: 100%;
}

.flex-col-xs-offset-0 {
  margin-left: 0;
}

.flex-col-xs-offset-1 {
  margin-left: 8.333%;
}

.flex-col-xs-offset-2 {
  margin-left: 16.667%;
}

.flex-col-xs-offset-3 {
  margin-left: 25%;
}

.flex-col-xs-offset-4 {
  margin-left: 33.333%;
}

.flex-col-xs-offset-5 {
  margin-left: 41.667%;
}

.flex-col-xs-offset-6 {
  margin-left: 50%;
}

.flex-col-xs-offset-7 {
  margin-left: 58.333%;
}

.flex-col-xs-offset-8 {
  margin-left: 66.667%;
}

.flex-col-xs-offset-9 {
  margin-left: 75%;
}

.flex-col-xs-offset-10 {
  margin-left: 83.333%;
}

.flex-col-xs-offset-11 {
  margin-left: 91.667%;
}



@media only screen and (min-width: $screen-sm) {
  .flex-container {
    width: 720px + $grid-gutter-width;
  }

  .flex-col-sm,
  .flex-col-sm-1,
  .flex-col-sm-2,
  .flex-col-sm-3,
  .flex-col-sm-4,
  .flex-col-sm-5,
  .flex-col-sm-6,
  .flex-col-sm-7,
  .flex-col-sm-8,
  .flex-col-sm-9,
  .flex-col-sm-10,
  .flex-col-sm-11,
  .flex-col-sm-12 {
    box-sizing: border-box;
    
    flex: 0 0 auto;
    // padding-right: ($grid-gutter-width / 2);
    // padding-left: ($grid-gutter-width / 2);
  }

  .flex-col-sm {
    width: 100%;
  }

  .flex-col-sm-1 {
    width: 8.333%;
  }

  .flex-col-sm-2 {
    width: 16.667%;
  }

  .flex-col-sm-3 {
    width: 25%;
  }

  .flex-col-sm-4 {
    width: 33.333%;
  }

  .flex-col-sm-5 {
    width: 41.667%;
  }

  .flex-col-sm-6 {
    width: 50%;
  }

  .flex-col-sm-7 {
    width: 58.333%;
  }

  .flex-col-sm-8 {
    width: 66.667%;
  }

  .flex-col-sm-9 {
    width: 75%;
  }

  .flex-col-sm-10 {
    width: 83.333%;
  }

  .flex-col-sm-11 {
    width: 91.667%;
  }

  .flex-col-sm-12 {
    width: 100%;
  }

  .flex-col-sm-offset-1 {
    margin-left: 8.333%;
  }

  .flex-col-sm-offset-2 {
    margin-left: 16.667%;
  }

  .flex-col-sm-offset-3 {
    margin-left: 25%;
  }

  .flex-col-sm-offset-4 {
    margin-left: 33.333%;
  }

  .flex-col-sm-offset-5 {
    margin-left: 41.667%;
  }

  .flex-col-sm-offset-6 {
    margin-left: 50%;
  }

  .flex-col-sm-offset-7 {
    margin-left: 58.333%;
  }

  .flex-col-sm-offset-8 {
    margin-left: 66.667%;
  }

  .flex-col-sm-offset-9 {
    margin-left: 75%;
  }

  .flex-col-sm-offset-10 {
    margin-left: 83.333%;
  }

  .flex-col-sm-offset-11 {
    margin-left: 91.667%;
  }

  .start-sm {
    justify-content: flex-start;
  }

  .center-sm {
    justify-content: center;
  }

  .end-sm {
    justify-content: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .stretch-md {
    align-content: stretch;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }


  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (min-width: $screen-md) {
  .flex-container {
    width: 940px + $grid-gutter-width;
  }

  .flex-col-md,
  .flex-col-md-1,
  .flex-col-md-2,
  .flex-col-md-3,
  .flex-col-md-4,
  .flex-col-md-5,
  .flex-col-md-6,
  .flex-col-md-7,
  .flex-col-md-8,
  .flex-col-md-9,
  .flex-col-md-10,
  .flex-col-md-11,
  .flex-col-md-12 {
    box-sizing: border-box;
    
    flex: 0 0 auto;
    // padding-right: ($grid-gutter-width / 2);
    // padding-left: ($grid-gutter-width / 2);
  }

  .flex-col-md {
    max-width: 100%;
  }

  .flex-col-md-1 {
    width: 8.333%;
  }

  .flex-col-md-2 {
    width: 16.667%;
  }

  .flex-col-md-3 {
    width: 25%;
  }

  .flex-col-md-4 {
    width: 33.333%;
  }

  .flex-col-md-5 {
    width: 41.667%;
  }

  .flex-col-md-6 {
    width: 50%;
  }

  .flex-col-md-7 {
    width: 58.333%;
  }

  .flex-col-md-8 {
    width: 66.667%;
  }

  .flex-col-md-9 {
    width: 75%;
  }

  .flex-col-md-10 {
    width: 83.333%;
  }

  .flex-col-md-11 {
    width: 91.667%;
  }

  .flex-col-md-12 {
    width: 100%;
  }

  .flex-col-md-offset-1 {
    margin-left: 8.333%;
  }

  .flex-col-md-offset-2 {
    margin-left: 16.667%;
  }

  .flex-col-md-offset-3 {
    margin-left: 25%;
  }

  .flex-col-md-offset-4 {
    margin-left: 33.333%;
  }

  .flex-col-md-offset-5 {
    margin-left: 41.667%;
  }

  .flex-col-md-offset-6 {
    margin-left: 50%;
  }

  .flex-col-md-offset-7 {
    margin-left: 58.333%;
  }

  .flex-col-md-offset-8 {
    margin-left: 66.667%;
  }

  .flex-col-md-offset-9 {
    margin-left: 75%;
  }

  .flex-col-md-offset-10 {
    margin-left: 83.333%;
  }

  .flex-col-md-offset-11 {
    margin-left: 91.667%;
  }

  .start-md {
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    justify-content: center;
    text-align: center;
  }

  .end-md {
    justify-content: flex-end;
    text-align: end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .stretch-md {
    align-content: stretch;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }


  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}


@media only screen and (min-width: $screen-lg + 1) {

  .flex-container {
    width: 1140px + $grid-gutter-width;
  }

  .flex-col-lg,
  .flex-col-lg-1,
  .flex-col-lg-2,
  .flex-col-lg-3,
  .flex-col-lg-4,
  .flex-col-lg-5,
  .flex-col-lg-6,
  .flex-col-lg-7,
  .flex-col-lg-8,
  .flex-col-lg-9,
  .flex-col-lg-10,
  .flex-col-lg-11,
  .flex-col-lg-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
  }

  .flex-col-lg {
    max-width: 100%;
  }

  .flex-col-lg-1 {
    width: 8.333%;
  }

  .flex-col-lg-2 {
    width: 16.667%;
  }

  .flex-col-lg-3 {
    width: 25%;
  }

  .flex-col-lg-4 {
    width: 33.333%;
  }

  .flex-col-lg-5 {
    width: 41.667%;
  }

  .flex-col-lg-6 {
    width: 50%;
  }

  .flex-col-lg-7 {
    width: 58.333%;
  }

  .flex-col-lg-8 {
    width: 66.667%;
  }

  .flex-col-lg-9 {
    width: 75%;
  }

  .flex-col-lg-10 {
    width: 83.333%;
  }

  .flex-col-lg-11 {
    width: 91.667%;
  }

  .flex-col-lg-12 {
    width: 100%;
  }

  .flex-col-lg-offset-1 {
    margin-left: 8.333%;
  }

  .flex-col-lg-offset-2 {
    margin-left: 16.667%;
  }

  .flex-col-lg-offset-3 {
    margin-left: 25%;
  }

  .flex-col-lg-offset-4 {
    margin-left: 33.333%;
  }

  .flex-col-lg-offset-5 {
    margin-left: 41.667%;
  }

  .flex-col-lg-offset-6 {
    margin-left: 50%;
  }

  .flex-col-lg-offset-7 {
    margin-left: 58.333%;
  }

  .flex-col-lg-offset-8 {
    margin-left: 66.667%;
  }

  .flex-col-lg-offset-9 {
    margin-left: 75%;
  }

  .flex-col-lg-offset-10 {
    margin-left: 83.333%;
  }

  .flex-col-lg-offset-11 {
    margin-left: 91.667%;
  }

  .start-lg {
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    justify-content: flex-end;
    text-align: end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .stretch-lg {
    align-content: stretch;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }


  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}

// Screen sizes less than 480px

@media (max-width: 480px) {
	.flex-col-full {
    width: 100%;
    margin-left: 0;
  }
}

// Justify content

.start {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.end {
  justify-content: flex-end;
}

.around {
  justify-content: space-around;
}

.between {
  justify-content: space-between;
}

// Align items

.top {
  align-items: flex-start;
}

.middle {
  align-items: center;
}

.bottom {
  align-items: flex-end;
}

.stretch {
  align-items: stretch;
}


.first {
  order: -1;
}

.last {
  order: 1;
}