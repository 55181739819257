/*------------------------------------*\
    @OBJECTS #JUSTIFIZE
\*------------------------------------*/

/*
    http://www.css-101.org/articles/responsive-boxes/magic-boxes.php

    <div class="justifize">
      <div class="justifize__box">
        justifize box content
      </div>
      <div class="justifize__box">
        justifize box content
      </div>
      <div class="justifize__box">
        justifize box content
      </div>
    </div>
*/

//JUSTIFIZE
.justifize {
    font-size: 1px;     /* IE 9, 10, 11 FIX */
    text-align: justify;

    &:after {
        content: "";
        display: inline-block;
        width: 100%;
    }
}

//JUSTIFIZE BOX
.justifize__box {
    font-size: $base-font-size;
    display: inline-block;
    text-align: left;
    vertical-align: middle;

    //GENERAL VERTICAL ALIGNMENT FOR JUSTIFIZE BOX
    .justifize--top & {
        vertical-align: top;
    }

    .justifize--bottom & {
        vertical-align: bottom;
    }
}
