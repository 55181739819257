/*------------------------------------*\
	@COMPONENTS #SIDENAV
\*------------------------------------*/
.sidenav-on {
	overflow: hidden;
}

.sidenav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	padding: 80px 30px;
	background-color: rgba(27, 25, 30, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 99999;
	opacity: 0;
	visibility: hidden;
	transition: all .5s ease;
	overflow-y: auto;

	.sidenav-on & {
		opacity: 1;
		visibility: visible;
	}
}

	.sidenav__list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 0px !important;

		li {
			padding-top: 10px;
			padding-bottom: 10px;
			list-style: none;
			position: relative;
			text-align: center;
		}

		a {
			text-transform: uppercase;
			font-weight: 700;

			&:active, &:focus {
				text-decoration: none;
			}

			&:active {
				color: $pink;
			}
		}
	}

	.sidenav__close {
		display: block;
		position: absolute;
		top: 25px;
		right: 20px;
		background-color: transparent;
		color: #fff !important;
		z-index: 99999;

		&:hover {
			opacity: 0.2;
		}
	}