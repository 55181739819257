/*------------------------------------*\
    @OBJECTS #NAV
\*------------------------------------*/

$screen-toggle:				768px;

$nav-back-primary:			orange;

.nav {
	position: relative;
	background-color: #aaa;
	z-index: 5010;

   	@media (max-width: $screen-toggle) {
   		margin-left: -24px;
   		margin-right: -24px;
   	}
	
	@media (max-width: $screen-toggle - 1) {
   		margin-left: -15px;
   		margin-right: -15px;		
	}

	&.nav--fixed {
		position: fixed;

		@media (max-width: $screen-toggle) {
			left: 24px;
			right: 24px;			
		}

		@media (max-width: $screen-toggle - 1) {
			left: 15px;
			right: 15px;						
		}
	}

	&.nav--center {
		text-align: center;
	}
}

.nav-fixed-in {
	padding-top: 52px;

	#nav {
		max-height: 100vh;
		overflow-y: auto;
	}
}

.nav__list, .navbar__logo {
	
}

.nav__list {
	list-style: none;
	padding: 0;
	background-color: gainsboro;
	position: relative;
	font-size: 16px;
	float: right;

	@media (max-width: $screen-toggle) {
		display: block;
		float: none;
		padding-top: 2px;
	}

	@media (min-width: $screen-toggle + 1) {
		display: inline-block !important;
	}

	@media (max-width: $screen-sm - 1) {
	}

	> li {
		display: inline-block;
		
		@media (max-width: $screen-toggle) {
			display: block;

			+ li {
				margin-top: 2px;
			}
		}

		+ li {
			@media (min-width: $screen-toggle + 1) {
				margin-left: -4px;
			}			
		}

		> a {
			display: inline-block;
			color: inherit;
			font-weight: 700;
			text-transform: uppercase;
			padding: 14px 20px;
			position: relative;
			text-decoration: none !important;

			@media (max-width: $screen-toggle) {
				width: 100%;
				text-align: center;
				background-color: $nav-back-primary;
				padding: 20px;
			}

			@media (max-width: $screen-sm) {
				padding: 10px;
				font-size: 16px;
			}

			&:hover {
				color: #fff;
				background-color: lighten($nav-back-primary,20);

				&:before, &:after {
					opacity: 1;
				}
			}
			
			&:before, &:after {
				@media (max-width: $screen-toggle) {
					display: none;
				}
			}
		}
	}
}



.navbar__logo {
	display: inline-block;
	padding: 10px;
	padding-left: 0;
	background-color: purple;
	float: left;

	@media (max-width: $screen-toggle) {
		float: none;
	}

	&:hover {
		img {
			@media (max-width: $screen-sm) {
				opacity: .55;
			}

			@media (min-width: $screen-sm) {
				animation-name: shake-hor;
				animation-duration: .5s;							
			}
		}
	}
}

@keyframes shake-hor {
	0% {
		transform: translateX(-4px);
	}

	20% {
		transform: translateX(4px);
	}

	40% {
		transform: translateX(-2px);
	}

	60% {
		transform: translateX(2px);
	}

}

.nav__toggle {
	background-color: transparent !important;
	padding: 0;
	position: absolute;
	right: 15px;
	top: 13px;

	@media (min-width: $screen-toggle + 1) {
		display: none;
	}

	span {
		display: block;
		background-color: #000;
		width: 36px;
		height: 4px;
		@include trans;
		transition-delay: 0s;

		+ span {
			margin-top: 6px;
		}
	}

	&.in {
		span {

			&:nth-of-type(2) {
				opacity: 0;
			}

			// &:nth-of-type(1) {
			// 	transform-origin: 0 50%;
			// 	transform: rotate(45deg) translate(4px, -9px);
			// 	transition-delay: .3s;
			// }

			// 	@media (max-width: $screen-sm - 1) {
			// 		transform: rotate(45deg) translate(3px, -7px);
			// 	}
			// }

			// &:nth-of-type(3) {
			// 	transform-origin: 0 50%;
			// 	transform: rotate(-45deg) translate(3px, 10px);

			// 	@media (max-width: $screen-sm - 1) {
			// 		transform: rotate(-45deg) translate(2px, 8px);
			// 	}
			// }

			&:nth-of-type(1),
			&:nth-of-type(3) {
				animation-duration: .5s;
				// animation-iteration-count: infinite;		
				animation-fill-mode: forwards;		
			}

			&:nth-of-type(1){
				animation-name: bar-top-collapse;
			}

			&:nth-of-type(3){
				animation-name: bar-bottom-collapse;
			}

			@keyframes bar-top-collapse {
				50% {
					transform: translateY(10px);
				}

				100% {
					transform: translateY(10px) rotate(-45deg);
				}
			}

			@keyframes bar-bottom-collapse {
				50% {
					transform: translateY(-10px);
				}

				100% {
					transform: translateY(-10px) rotate(45deg);
				}
			}
		}
	}
}

	.navbar__sample {
		font-size: 22px;
		color: #fff;
		text-decoration: none !important;	
		display: inline-block;
		width: 200px;
		padding: 0 20px;
		font-weight: 700;
	}

.nav-backdrop {
	position: fixed;
	z-index: 5000;
	background-color: #000;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0;

	@media (min-width: $screen-toggle + 1) {
		display: none;
	}

	&.nav-backdrop--show {
		opacity: .65;
	}
}