/*------------------------------------*\
    @OBJECTS #EMBEDDED
\*------------------------------------*/

/*
    BASED ON https://github.com/suitcss/components-flex-embed/blob/master/flex-embed.css, mashed up with http://www.mademyday.de/css-height-equals-width-with-pure-css.html

    <div class="embedded">
      [iframe|object|embed]
    </div>
*/

//EMBEDDED
.embedded {
    display: block;
    overflow: hidden;
    position: relative;

    /**
     * Descendant: media element
     *
     * Utility of the component is improved by provide an explicit descendant
     * class. However, it will work automatically for iframes, embeds, and objects
     * to account for times when you cannot modify the attributes for 3rd party
     * widget code.
     */
    .embedded__item,
    video,
    iframe,
    embed,
    object {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    /**
     * A pseudo-element shunt is used to provide support for `max-height`. The
     * `max-height` property has no affect when using a percentage-padding to set
     * the aspect ratio.
     */
    &:before {
        content: "";
        display: block;
        padding-bottom: 100%;  /* initial ratio of 1:1*/
        width: 100%;
    }
}


/**
 * Modifier: 3:1 aspect ratio
 */
.embedded--3by1:before {
    padding-bottom: round(100% / 3);
}

/**
 * Modifier: 2:1 aspect ratio
 */
.embedded--2by1:before {
    padding-bottom: 50%;
}

/**
 * Modifier: 16:9 aspect ratio
 */
.embedded--16by9:before {
    padding-bottom: 56.25%;
}

/**
 * Modifier: 4:3 aspect ratio
 */
.embedded--4by3:before {
    padding-bottom: 75%;
}

//EMBEDDED CENTERED
.embedded--center {
    .embedded__item,
    video,
    iframe,
    embed,
    objectd {
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%;
    }
}
