/*------------------------------------*\
	@COMPONENTS #LAYOUT
\*------------------------------------*/

.body {
	overflow-x: hidden;
}

.main {
	min-height: 100vh;
	position: relative;

	.header {
		position: absolute;
		width: 100%;
		z-index: 1;

		@media (max-width: $screen-md) {
			z-index: 9999;
			
		}
	}
}