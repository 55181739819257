/*------------------------------------*\
	@TRUMPS #PRINT
\*------------------------------------*/

/**
 * Very crude, reset-like styles taken from the HTML5 Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/3c3c5e64604209a4d63e1e4c48dd245d45fadfd9/css/main.css#L200-L234
 */

@media print {

    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /**
     * Don’t show links that are fragment identifiers, or use the `javascript:`
     * pseudo protocol.
     */

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }
}