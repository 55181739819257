//------------------------------------\\
//  @SETTINGS #VARIABLES
//------------------------------------//

//COLORS
$white: #fff ;
$pink: #ff00ff;


// Basic styles
$base-bgr:                            	#fff;
$base-font: 							Quicksand, sans-serif;


$base-font-size:                        16px;
$base-line-height:                      1.42;
$base-font-weight:                      400;

$text-clr: 								#000;
$text-alt: 								#333;
$placeholder-clr:						$white;
$link-clr:								$white;
$link-hover:							lighten($white,10);

$border:								#e5e5e5;


// Basic colors
$primary: $white;
$alt: $white;

// Backgrounds
$bgr: $white;


// Layout
$space:                          		20px;
$radius:								3px;


// Breakpoints
$mobile:                             	480px;
$tablet:                              	768px;
$desktop:                         		1024px;


// Select Text
$selected-text-bgr:						$white;
$selected-text-clr:						#fff;