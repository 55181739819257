/*------------------------------------*\
	@COMPONENTS #HEADER
\*------------------------------------*/

.header {
	padding: 25px 30px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;

	a {
		padding: 0px !important;

        &:hover {
            opacity: 0.4;
        }
	}
}

	.header__logo {
		margin-right: auto;

		&:hover {
			opacity: 1 !important;
			transform: rotate(-3deg);
		}

		img {
			max-width: 400px;
		}

		@media (max-width: $screen-sm) {
			display: none;
		}
	}

	.header__button {
		display: none;
		margin-right: 20px;
		padding: 0;

		.icon {
			width: 24px;
			height: 24px;
		}

	    @media (max-width: $screen-md) {
	        display: inline-block;
	        background-color: transparent;

	        &:hover {
	            opacity: 0.4;
	        }
	    }
	}

	.header__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-width: 100px !important;
		margin-left: auto;

		@media (max-width: 560px) {
			min-width: 80px !important;
		}
	}

