/*------------------------------------*\
	@COMPONENTS #MENU
\*------------------------------------*/

.menu-wrap {
	padding-right: 10px;
	position: relative;
	flex: 1;
	max-width: 50%;
	margin-left: auto;

	&:after {
		content: '';
		width: 100%;
		height: 2px;
		background-color: #fff;
		position: absolute;
		left: 100%;
		top: 32px;
	}

	@media (max-width: $screen-md) {
		display: none;
	}
}

.menu {
	display: flex;
	align-items: center;
	padding-left: 0; 

	li {
		display: inline-block;
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}
	}

	.active a {
		color: $pink;

		&:before {
			background-color: $pink;
			opacity: 1;
		}
	}

	a {
		color: #fff;
		font-size: 20px;
		font-weight: 700;
		position: relative;

		&:before {
			content: '';
			width: 100%;
			height: 2px;
			background-color: #bbb;
			position: absolute;
			transition: all .8s ease 0s;
			left: 0;
			bottom: -12px;
			z-index: 1;
			opacity: 0;
		}

		&:hover {
			color: #bbb;
			
			&:before {
				opacity: 1;
			}
		}

		&:active, &:focus {
			text-decoration: none !important;
		}
	}
}

	.menu--left {
		padding-left: 0px;
		margin-bottom: 6px !important;

		li {
			&:before {
				content: '';
				width: 100%;
				height: 2px;
				background-color: #fff;
				position: absolute;
				transition: all .8s ease 0s;
				left: 0;
				top: 32px;
				z-index: -1
			}
		}	

		a {
			&:before {
				bottom: -8px;
			}

			&:hover {
				&:before {
					bottom: -8px;
				}		
			}
		}
	}

	.menu--right {
		justify-content: flex-end;

		a {
			&:before {
				top: -4px;
			}

			&:hover, &:active {
				&:before {
					top: -4px;
				}		
			}
		}
	}