/*------------------------------------*\
    @OBJECTS #ICONS
\*------------------------------------*/

.icon {
    fill: currentColor;
    height: 16px;
    width: 16px;
    vertical-align: middle;
    display: inline-block;
    @include trans;
}