/*------------------------------------*\
    @BASE #LISTS
\*------------------------------------*/

ul {
    list-style-type: disc;
}

ol {
    list-style: decimal;
}

ul,
ol {
    // margin-left: 1em;
    // margin-right: 1em;
}

/**
 * Remove extra vertical spacing when nesting lists.
 */
li {

    > ul,
    > ol {
        margin-bottom: 0;
    }

}