/*------------------------------------*\
	@COMPONENTS #MAIN
\*------------------------------------*/

.main__wrap {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	min-height: 100vh;
	padding: 100px 0;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		width: 77vmax; 
		height: 77vmax; 
		border-radius: 9999px;
		background-color: #0cc81a;
		opacity: 0.2;
		position: absolute;
		top: -25vmax;
		right: -20vmax;
	}

	@media (max-width: $screen-md) {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	@media (max-width: $screen-xs) {
		padding-bottom: 15px;
	}
}

.main__wrap--overlay {
	&:after {
		content: "";
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(27, 25, 30, 0.4);
	}
}

	

