/*------------------------------------*\
    @OBJECTS #BOX
\*------------------------------------*/

.box {
    @include clearfix;
    display: block;

    > :last-child {
        margin-bottom: 0;
    }
}

