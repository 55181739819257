/*------------------------------------*\
    @COMPONENTS #FORMS
\*------------------------------------*/

input,
.form-control {
	-webkit-appearance: none;       /* removing inner shadow on iOS inputs */
    -webkit-font-smoothing: inherit;
    background: none;
	box-shadow: none !important;
	font-family: $base-font;
    font-size: $base-font-size;
    
	border: 1px solid $border;

	&:hover, &:focus {
		border-color: $alt;
	}

	&:invalid {
        box-shadow: none;  /* fixes mozilla red outline (shadow) */
    }

	&[readonly] {
        cursor: text;
    }

    &::-ms-clear {
        display: none; /* remove IE clear icon */
    }

    /*PLACEHOLDER*/
    &::-webkit-input-placeholder {
	   color: $placeholder-clr;
	}

	&:-moz-placeholder { /* Firefox 18- */
	   color: $placeholder-clr; 
	   opacity: 1; 
	}

	&::-moz-placeholder {  /* Firefox 19+ */
	   color: $placeholder-clr;
	   opacity: 1;  
	}

	&:-ms-input-placeholder {  
	   color: $placeholder-clr;  
	}
}

.form-group {
	@include clearfix;

	&:last-child {
		margin-bottom: 0;
	}
}