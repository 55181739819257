/*------------------------------------*\
	@COMPONENTS #ICON
\*------------------------------------*/

.icon-social-facebook {
	color: #fff;
	width: 12px;
	height: 20px;
}

.icon-close {
	color: #fff;
	width: 19px;
	height: 19px;
}

.icon-hamburger {
	background-color: transparent;
	color: #fff !important;

}