/*------------------------------------*\
	@COMPONENTS #CONTENT
\*------------------------------------*/

.container {
	position: relative;
	z-index: 999;
	width: 100%;
	max-width: 1170px;
}

.content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	min-height: 700px;

	@media (max-width: $screen-md) {
		min-height: 0;
		flex-direction: column;
	}
}

	.content__text {
		background-color: rgba(27, 25, 30, 0.5);
		padding: 50px;
		padding-left: 0;
		position: relative;
		max-width: 520px;

		h1 {
			color: #fff;
			font-size: 28px;
			text-transform: uppercase;
			margin-bottom: 40px;

			span {
				text-transform: lowercase;
			}
		}

		h2 {
			font-size: 20px;
		}

		h3 {
			font-size: 16px;
			font-weight: 400;
		}

		&:before {
			content: '';
			background-color: rgba(27, 25, 30, 0.5);
			width: 100vw;
			height: 100%;
			position: absolute;
			top: 0;
			right: 100%;
		}

		a {
			color: $pink;

			&:hover {
				opacity: .5;
			}
		}

		@media (max-width: $screen-lg) {
			margin-right: 10px;

			&:before {
				display: none;
			}
		}

		@media (max-width: $screen-md) {
			padding: 30px;
		}

		@media (max-width: 560px) {
			width: 100%;
			padding: 20px;
			margin-right: 0px !important;

			h1 {
				font-size: 20px;
			}

			h2 {
				font-size: 18px;
			}

			h3 {
				font-size: 16px;
			}

			p {
				font-size: 14px;
			}
		}
	}

	.content__img {
		align-self: flex-start;
		
		@media (max-width: $screen-md) {
			align-self:center;
		}
	}

	.content__logo {
		display: inline-block;
	}

	.content__list {
		margin-bottom: 30px;

		ul {
			padding-left: 0px !important;
		}

		li {
			list-style: none;
		}

		@media (max-width: 560px) {
			li {
				font-size: 12px;
			}
		}
	}

	.content__link {
		margin-bottom: 20px;
		
		a {
			display: block !important;

			&:hover {
				opacity: 0.4;
			}
		}

		span {
			display: block;
		}

		@media (max-width: 560px) {
			a {
				font-size: 12px;
			}
		}
	}

	.content__logos {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		> * {
			margin-right: 20px;
		}

		img {
			max-width: 80px;
			margin-bottom: 0;
		}

		.logo-lg {
			max-width: 180px;
		}
	}


	.content__cover {
		position: relative;
		z-index: 100;
		margin: auto;

		@media (min-width: $screen-sm + 1) {
			display: none;
		}
	}