/*------------------------------------*\
	@COMPONENTS #BUTTONS
\*------------------------------------*/

.btn--primary {
    background-color: $primary;
    color: #fff;

    &:hover {
        background-color: darken($primary,10);
    }

    &:focus {
        background-color: $primary;

        &:hover {
            background-color: darken($primary,10);
        }
    }
}

